@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Navigation buttons */
.btn-nav {
    transition: background-color 0.2s, color 0.2s;
}

/* Primary and secondary buttons */
.btn-primary,
.btn-secondary {
    transition: background-color 0.2s;
}

/* Team cards */
.team-card {
    transition: box-shadow 0.2s;
}

.team-card h3 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.team-card .team-short-name {
    font-size: 0.9rem;
    color: #718096;
    margin-left: 0.5rem;
}

.team-card .team-description {
    font-size: 0.9rem;
    color: #4a5568;
    margin-top: 0.5rem;
}

/* Interaction item styles */
.interaction-item {
    display: flex;
    flex-direction: column;
}

.interaction-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

/* Color styles for each interaction type */
.interaction-btn[data-type="none"] {
    background-color: white;
    color: black;
}

.interaction-btn[data-type="collaboration"] {
    background-color: #FF6B6B;
    color: white;
}

.interaction-btn[data-type="x-as-a-service"] {
    background-color: #4ECDC4;
    color: white;
}

.interaction-btn[data-type="facilitation"] {
    background-color: #FFA500;
    color: white;
}

.interaction-btn svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
    display: inline-block;
}

/* New media query for larger screens */
@media (min-width: 640px) {
    .interaction-item {
        flex-direction: row;
        align-items: center;
    }

    .team-info {
        flex: 0 0 auto;
        margin-right: 1rem;
        margin-bottom: 0;
    }

    .interaction-buttons {
        flex: 1;
        margin-top: 0;
    }
}

/* Graph styles */
#graph {
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
}

/* Responsive adjustments */
@media (max-width: 640px) {
    .btn-nav {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
    }
}

@media (min-width: 640px) {
    .interaction-buttons {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}
