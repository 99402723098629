@import "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap";
body {
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

.btn-nav {
  transition: background-color .2s, color .2s;
}

.btn-primary, .btn-secondary {
  transition: background-color .2s;
}

.team-card {
  transition: box-shadow .2s;
}

.team-card h3 {
  margin-bottom: .5rem;
  font-size: 1.1rem;
}

.team-card .team-short-name {
  color: #718096;
  margin-left: .5rem;
  font-size: .9rem;
}

.team-card .team-description {
  color: #4a5568;
  margin-top: .5rem;
  font-size: .9rem;
}

.interaction-item {
  flex-direction: column;
  display: flex;
}

.interaction-buttons {
  flex-wrap: wrap;
  gap: .5rem;
  margin-top: .5rem;
  display: flex;
}

.interaction-btn[data-type="none"] {
  color: #000;
  background-color: #fff;
}

.interaction-btn[data-type="collaboration"] {
  color: #fff;
  background-color: #ff6b6b;
}

.interaction-btn[data-type="x-as-a-service"] {
  color: #fff;
  background-color: #4ecdc4;
}

.interaction-btn[data-type="facilitation"] {
  color: #fff;
  background-color: orange;
}

.interaction-btn svg {
  width: 1rem;
  height: 1rem;
  margin-right: .25rem;
  display: inline-block;
}

@media (width >= 640px) {
  .interaction-item {
    flex-direction: row;
    align-items: center;
  }

  .team-info {
    flex: none;
    margin-bottom: 0;
    margin-right: 1rem;
  }

  .interaction-buttons {
    flex: 1;
    margin-top: 0;
  }
}

#graph {
  border: 1px solid #e2e8f0;
  border-radius: .5rem;
}

@media (width <= 640px) {
  .btn-nav {
    padding: .5rem 1rem;
    font-size: .875rem;
  }
}

@media (width >= 640px) {
  .interaction-buttons {
    grid-template-columns: repeat(4, 1fr);
    display: grid;
  }
}
/*# sourceMappingURL=index.2e49a20f.css.map */
